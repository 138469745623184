.App {
  text-align: center;
  background-color: #18181a;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.imagesContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 10px;
}

.images {
  margin: 10px;
  width: 150px;
  margin-left: 20px;
  margin-right: 10px;
  border-radius: 6%;
  object-fit: cover;
}

.logo {
  width: 180px;
  border-radius: 6%;
  object-fit: cover;
  margin: 10px;
}

.banner {
  background-color: #132E49;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
}

.taglineContainer {
  color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px;
}

.headerTitle {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 36px;
  font-weight: 700;
}

.subtitle {
  margin-bottom: 0px;
  font-size: 20px;
}

div.social_stuff {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #FDF6EE;
  color: #132E49;
  font-size: 30px;
}

div.scheduleContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #132E49;
}

div.scheduleContainer div.title {
  width: 820px;
  text-align: left;
  color: #ffffff;
  font-weight: 900;
  font-size: 36px;
  padding-bottom: 10px;
  padding-top: 20px;
}

div.schedule {
  width: 820px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 60px;
  background-color: #FDF6EE;
  padding: 20px;
  margin-bottom: 100px;

}

div.tableRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  background-color: #F2EBE3;
  width: 750px;
  height: 150px;
  border-radius: 20%/100%;
  margin: 10px;
  padding-left: 16px;
  font-family: Arial, Helvetica, sans-serif;
}


div.tableRow div.showtime {
  height: 120px;
  width: 120px;
  border-radius: 50%;
  background-color: #132E49;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 14px;
}

div.tableRow div.showtime div.day_of_week{
  font-size: 18px;
  font-weight: 800;
  color: #64BCC8;
}

div.tableRow div.showtime div.date {
  font-size: 30px;
  line-height: 24px;
  font-weight: 800;
  color: #ffffff;
}
 
div.tableRow div.showtime div.month_and_year {
  font-size: 14px;
  font-weight: 800;
  color: #64BCC8;
}

div.tableRow div.gig_times {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 60px;
  color: #224861;
  font-size: 12px;
  font-weight: 700;
  background-color: #64BCC8;
  border-radius: 25%/50%;
}

div.tableRow div.title {
  color: #132E49;
  font-size: 14px;
  width: 25%;
  min-width: 200px;
}

div.tableRow div.venue {
  color: #132E49;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  font-size: 14px;
  width: 450px;
  padding: 20px;
}

div.tableRow div.venue div.name {
  color: #132E49;
  font-size: 36px;
  line-height: 36px;
  font-weight: bold;
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
}

div.tableRow div.venue div.address {
  font-size: 18px;
  color: #6F6261;
}

.contentBlock {
  margin: 10px;
}





.audio-player {
  background-color: #b7b7b7;
  padding: 0.5rem;
  border-radius: 10px;
  filter: invert(100%);
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


@media (max-width: 830px) {
  
  div.scheduleContainer div.title {
    width: 370px;
    font-size: 18px;
  }

  div.schedule {
    width: 370px;
    border-radius: 30px;
    padding: 10px;
    margin-bottom: 40px;
  }

  div.tableRow {
    width: 350px;
    height: 70px;
    margin: 4px;
    padding-left: 8px;
  }

  div.tableRow div.showtime {
    height: 52px;
    width: 52px;
  }
  
  div.tableRow div.showtime div.day_of_week{
    font-size: 8px;
  }
  
  div.tableRow div.showtime div.date {
    font-size: 10px;
    line-height: 8px;
  }
   
  div.tableRow div.showtime div.month_and_year {
    font-size: 8px;
  }

  div.tableRow div.gig_times {
    width: 80px;
    height: 40px;
    font-size: 8px;
  }
  
  div.tableRow div.title {
    font-size: 10px;
    width: 25%;
    min-width: 100px;
  }

  div.tableRow div.venue {
    font-size: 10px;
    width: 190px;
    padding: 10px;
  }
  
  div.tableRow div.venue div.name {
    font-size: 14px;
    line-height: 12px;
  }
  
  div.tableRow div.venue div.address {
    font-size: 10px;
  }
}
